import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { CourseEditor } from './course-editor/course-editor.component';
import { Course } from '../../../interfaces/global-config.interfaces';
import { firstValueFrom } from 'rxjs';
import { LanguagesControllerService } from 'src/app/core/openapi';
import { get } from 'lodash';
import { Language } from '../../../interfaces/global-config.interfaces';

const DEFAULT_LANG = 'en_ca';

interface LearningManagementChanges {
  getCourseList?: SimpleChanges;
  searchTerm?: SimpleChanges;
}

@Component({
  selector: 'app-learning-management-system',
  templateUrl: './learning-management-system.component.html',
  styleUrls: ['./learning-management-system.component.scss'],
})
export class LearningManagementSystemComponent implements OnChanges {
  @Input() getCourseList: Course[];
  @Input() searchTerm: string = '';
  @Output() reset = new EventEmitter();
  @Output() searchTermChange = new EventEmitter<string>();

  public languages: Language[] = [];

  public currentLanguage: string;
  public courseList: Course[] = [];
  public courseListOriginal: Course[] = [];
  public coursesByLanguage: Map<string, Course[]> = new Map();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialog: MatDialog,
    private languagesService: LanguagesControllerService,
  ) {}

  async ngOnChanges(changes: LearningManagementChanges) {
    if (changes?.searchTerm) {
      this.search(this.searchTerm);
    }
    if (changes?.getCourseList) {
      this.initCoursePage();
    }
  }

  private async initCoursePage() {
    await this.splitCoursesByLanguage();
    const browserLang = this.getBrowserLanguage();
    this.currentLanguage = browserLang;
  }

  private getBrowserLanguage(): string {
    const browserLang = window.navigator.language.replace('-', '_').toLowerCase();
    const foundLanguage = this.languages.find((lang) => lang.languageCode === browserLang);
    if (foundLanguage) {
      const languageCode = get(foundLanguage, 'languageCode', DEFAULT_LANG);
      this.toggleLanguage(languageCode);
      return languageCode;
    }
  }

  private async getLanguages() {
    const languages = await firstValueFrom(this.languagesService.languagesControllerGetLanguages());

    return languages;
  }

  public async splitCoursesByLanguage() {
    if (!this.getCourseList) return;

    this.courseListOriginal = [...this.getCourseList];
    this.coursesByLanguage.clear();
    this.languages = [];

    const languages = await this.getLanguages();

    this.courseListOriginal = this.courseListOriginal.map((course) => ({
      ...course,
      language: course.language?.toLowerCase() ?? DEFAULT_LANG,
    }));

    languages.forEach((lang: Language) => {
      const coursesForLanguage = this.courseListOriginal.filter(
        (course) => course.language?.toLowerCase() === lang.languageCode.toLowerCase(),
      );

      if (coursesForLanguage.length > 0) {
        this.coursesByLanguage.set(lang.languageCode, coursesForLanguage);
        this.languages.push(lang);
      }
    });

    this.languageToggle(this.currentLanguage);
    this.alphabeticalOrderLanguage();
  }

  private alphabeticalOrderLanguage() {
    const defaultLangIndex = this.languages.findIndex((lang) => lang.languageCode === DEFAULT_LANG);

    if (defaultLangIndex !== -1) {
      const defaultLang = this.languages.splice(defaultLangIndex, 1)[0];
      this.languages.sort((a, b) => a.name.localeCompare(b.name));
      this.languages.unshift(defaultLang);
    } else {
      this.languages.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  private languageToggle(languageCode: string) {
    this.courseList = this.coursesByLanguage.get(languageCode) ?? this.coursesByLanguage.get(DEFAULT_LANG) ?? [];
  }

  public getLessonsAmount(course: Course): number {
    return course.lessons?.length ?? 0;
  }

  public async deleteCourse(course: Course) {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this course?',
        'Yes',
        'No',
      )
      .then(async () => {
        await this.rest.delete('delete/course/id/' + course.id);
        this.reset.emit();
        this.snack.open('Course deleted!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public duplicateCourse(course: Course) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to duplicate this course?', 'Yes', 'No')
      .then(async () => {
        const courseCopy = { ...course };
        delete courseCopy.id;

        await this.rest.post('courses', courseCopy);
        this.reset.emit();
        this.snack.open(`Course ${course.name} duplicated!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        });
      });
  }

  public openCourseEditor(course: Course) {
    const courseToEdit = {
      ...course,
      enabledToAll: course.enabledToAll ?? true,
      useForGameCategory: course.useForGameCategory ?? false,
    };

    const dialogRef = this.dialog.open(CourseEditor, {
      width: '1400px',
      height: '900px',
      data: courseToEdit,
      panelClass: 'modal-border',
    });

    dialogRef.afterClosed().subscribe((updatedCourse) => {
      if (updatedCourse) {
        this.updateCourseList(updatedCourse);
      }
    });
  }

  // Flow Functions

  public toggleLanguage(languageCode: string) {
    this.currentLanguage = languageCode;
    this.languageToggle(languageCode);
    this.searchTerm = '';
  }

  private search(searchTerm: string) {
    if (!searchTerm) {
      this.courseList = this.coursesByLanguage.get(this.currentLanguage) ?? [];
      return;
    }

    const term = searchTerm.toLowerCase().trim();
    const originalCourses = this.coursesByLanguage.get(this.currentLanguage) ?? [];

    this.courseList = originalCourses.filter(
      (course) =>
        course.name.toLowerCase().includes(term) ||
        course.title.toLowerCase().includes(term) ||
        course.lessons?.some(
          (lesson) =>
            lesson.title.toLowerCase().includes(term) ||
            lesson.sections?.some((section) => section.title.toLowerCase().includes(term)),
        ) ||
        false,
    );
  }

  private updateCourseList(course: Course) {
    const foundUpdatedCourse = this.getCourseList.find((c) => c.id === course.id);
    if (foundUpdatedCourse) {
      const index = this.getCourseList.findIndex((c) => c.id === foundUpdatedCourse.id);

      if (index !== -1) {
        this.getCourseList[index] = course;
        this.initCoursePage();
      }
    }
  }
}
