import { Component, OnInit } from '@angular/core';
import { faGraduationCap, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Course } from '../interfaces/global-config.interfaces';
import { CourseCreator } from './components/learning-management-system/course-creator/course-creator.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-course-configurations',
  templateUrl: './course-configurations.component.html',
  styleUrls: ['../configurations.style.scss', './course-configurations.component.scss'],
})
export class CourseConfigurationsComponent implements OnInit {
  public readonly learningManagementSystemIcon: IconDefinition = faGraduationCap;
  public readonly faPlus: IconDefinition = faPlus;

  public loading: boolean;
  public courseList: Course[] = [];
  public searchTerm: string = '';

  public selectedOption = 'courses';

  constructor(
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private rest: RestAPIService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {
    this.loading = true;
    try {
      await this.getCourseList();
    } catch (error) {
      // @TODO add warning
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
  public async getCourseList() {
    this.courseList = await this._rest.get('courses');
  }

  public reset() {
    this.ngOnInit();
  }

  public setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  public onSearchChange(term: string) {
    this.searchTerm = term;
  }

  public openCourseCreator() {
    const dialog = this.dialog.open(CourseCreator, {
      width: '400px',
      height: '650px',
      panelClass: 'modal-border',
      disableClose: true,
    });

    dialog.afterClosed().subscribe(async (newCourse) => {
      if (newCourse) {
        await this.rest.post('courses', newCourse);
        this.reset();
        this.snack.open('Course created!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        });
      }
    });
  }
}
