import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faCamera, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { User } from 'src/app/shared/models';
import { RestAPIService } from '../../../../services/rest/rest-api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OrganizationsListService } from '../../menus/organizations-menu/organizations-list/organizations-list.service';
import { DataManager, ManagerActions, ManagerTypes, Outsider } from 'src/app/shared/interfaces/Manager.interface';
import { Organization } from 'src/app/core/openapi';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';

const DEFAULT_LANG = 'en';
@Component({
  selector: 'organizations-create-modal',
  templateUrl: './organizations-create-modal.component.html',
  styleUrls: ['./organizations-create-modal.component.scss'],
})
export class OrganizationsCreateModalComponent implements OnInit {
  //Config for the view form
  public readonly PortalConfig = PortalConfig;
  public readonly camera: IconDefinition = faCamera;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public orgAcc: User;
  public org: Organization;
  public stateSave: boolean = false;
  public isSchool: boolean = false;

  public organizationForm: Outsider = {
    name: '',
    email: '',
    phoneNumber: '',
    position: '',
    professionalAssociation: '',
    language: '',
    referredBy: '',
    address: {
      country: '',
      address: '',
      province: '',
      postalCode: '',
    },
    subdomain: '',
    logo: '',
    isSchool: false,
    isOutsider: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataManager,
    public dialogRef: MatDialogRef<OrganizationsCreateModalComponent>,
    private _snackBar: MatSnackBar,
    private _restService: RestAPIService,
    private _auth: AuthService,
    private _organizationsListService: OrganizationsListService,
    private cloudinary: CloudnaryService,
  ) {
    if (data?.organization) {
      this.organizationForm = {
        ...data.organization.organization,
      };
    }
  }

  async ngOnInit() {
    const currentOrg = this._auth.getOrgAcc();
    this.isSchool = get(currentOrg, 'organization.isSchool', false);
  }

  async save() {
    switch (this.data?.type) {
      case ManagerActions.CREATE:
        this.stateSave = true;
        await this.create();
        this.stateSave = false;
        break;

      case ManagerActions.UPDATE:
        this.stateSave = true;
        await this.update();
        this.stateSave = false;
        break;

      default:
        break;
    }
  }
  async create() {
    try {
      const currentUser = await this._auth.getUser();
      const currentOrg = this._auth.getOrgAcc();

      // if the current organization is a school, the created outsider will also be a school
      this.organizationForm.isSchool = get(currentOrg, 'organization.isSchool', false) ? true : false;

      const payload = {
        user: {
          organization: this.organizationForm,
        },
        reseller: currentUser.id,
        verifyType: ManagerTypes.outsider.toLowerCase(),
        org: currentUser.organization.name,
        subdomain: this.organizationForm.subdomain,
        orgEmail: currentOrg.email,
        lang: DEFAULT_LANG,
      };

      const organization = await this._restService.post('organization/outsider', payload);

      if (!organization) {
        throw new Error('Organization create failed! Please try again. If the problem persist contact our support');
      }
      this._organizationsListService.changeOrganization(organization);
      this.close();
      this.openSnackbar(this.isSchool ? 'School created!' : 'Organization created!');
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  async update() {
    try {
      const outsider = await this._restService.put('organization/outsider', {
        organization: this.organizationForm,
        outsiderId: this.data?.organization?.organization.id,
      });

      if (!outsider) {
        throw new Error('Organization update failed! Please try again. If the problem persist contact our support');
      }

      this._organizationsListService.changeOrganization(outsider);
      this.close();
      this.openSnackbar(this.isSchool ? 'School updated!' : 'Organization updated!');
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  public close() {
    const status = { status: true, updating: this.stateSave };
    this.dialogRef.close(status);
  }

  /**
   * Handle Google Autocomplete by populating other address
   * fields when user changes address
   *
   * @param place An object provided by Google that has all the details of a place the user selects.
   */
  handleAddressChange(place) {
    const location = this.setLocation(place);

    if ('address' in this.organizationForm) {
      this.organizationForm.address.address = `${location.streetNumber} ${location.route}, ${location.locality}`;
      this.organizationForm.address.postalCode = location.postalCode;
      this.organizationForm.address.country = location.country;
      this.organizationForm.address.province = location.province;
    }
  }

  private setLocation(place) {
    return {
      streetNumber: this.getAddressComponent(place, ['street_number']),
      route: this.getAddressComponent(place, ['route']),
      locality: this.getAddressComponent(place, ['locality', 'sublocality', 'neighborhood']),
      postalCode: this.getAddressComponent(place, ['postal_code']),
      country: this.getAddressComponent(place, ['country']),
      province: this.getAddressComponent(place, ['administrative_area_level_1']),
    };
  }

  private getAddressComponent(place, types: string[]) {
    const { address_components } = place;

    for (let i = 0; i < types.length; i++) {
      const result = address_components.find((item) => get(item, 'types').includes(types[i]));
      if (result) {
        return result.long_name;
      }
    }
    return '';
  }

  public fillSubdomain(): void {
    const companyName = this.organizationForm?.name;
    if (companyName && companyName.trim() !== '') {
      this.organizationForm.subdomain = this._getCompanyNameSubdomain(companyName);
    }
  }

  private _getCompanyNameSubdomain(companyName: string): string {
    return companyName
      .toLowerCase()
      .split(' ')
      .join('')
      .replace(/[^a-zA-Z0-9]/g, '');
  }

  public async addOrganizationImage() {
    try {
      const image: string = await this.cloudinary.handleUpload();
      this.organizationForm.logo = image;
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
