import { Patron } from 'src/app/core/openapi/model/patron';

interface BaseUser {
  id?: string;
  email?: string;
  name?: string;
  status?: 'active' | 'not-active';
  emailVerified?: boolean;
  fullname?: string;
  nickname?: string;
  familyName?: string;
  givenName?: string;
}

export interface DataManager {
  type?: string;
  clientId?: string;
  admin?: AdminManager;
  manager?: OrganizationManager;
  student?: Student;
  client?: Client;
  organization?: Outsider;
  isSettings?: boolean;
}

interface Address {
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
}

export interface Client extends BaseUser {
  accountId?: string;
  address1?: string;
  archived?: boolean;
  familyName?: string;
  givenName?: string;
  image?: string;
  language?: string;
  nickname?: string;
  organizationId?: string;
}

export interface Student extends BaseUser {
  id?: string;
  familyName?: string;
  givenName: string;
  fullname?: string;
  nickname?: string;
  birthdate?: string;
  gender?: string;
  image?: string;
  programs?: Array<object>;
  archived?: boolean;
  language?: string;
  createdBy?: string;
  alertOnSessionEnd?: boolean;
  certificateIssued?: boolean;
  enableHomeAccess?: boolean;
  theme?: string;
  agenda?: object;
  patronId?: string;
}

export interface Outsider extends BaseUser {
  createdAt?: string;
  tokenId?: string;
  stripeId?: string;
  lastLogin?: string;
  passwordUpdated?: boolean;
  pendingRegistration?: boolean;
  availableTokens?: any[];
  firstTokenPurchased?: boolean;
  organization?: Organization;
  webpage?: string;
  credits?: number;
  type?: string;
  logo?: string;
  subdomain?: string;
  language?: string;
  phoneNumber?: string;
  address?: Address;
  position?: string;
  professionalAssociation?: string;
  referredBy?: string;
  isSchool: boolean;
  isOutsider?: boolean;
}
interface Organization {
  id?: string;
  logo?: string;
  name?: string;
  acceptedTerms?: boolean;
  address?: Address;
  phoneNumber?: string;
  position?: string;
  professionalAssociation?: string;
  referredBy?: string;
  subdomain?: string;
  allowSignup?: boolean;
  isTrusted?: boolean;
  isReseller?: boolean;
  isB2CProvider?: boolean;
  isOutsider?: boolean;
  language?: string;
  b2cTokenPrice?: number;
  b2cCurrency?: string;
  portalPrice?: number;
  maximumTokenDebit?: number;
  allowSubscription?: boolean;
  allowB2cSplitPayments?: boolean;
  b2cPaymentType?: string;
  b2cAllowedPaymentTypes?: string[];
  accountId?: string;
  email?: string;
  allowProgressRerun?: boolean;
  certifiedProvider?: boolean;
  isSchool: boolean;
}

export interface AdminManager extends BaseUser {
  createdAt?: string;
  tokenId?: string;
  stripeId?: string;
  role?: string;
  orgId?: string;
  passwordUpdated?: boolean;
  pendingRegistration?: boolean;
  patron?: Patron;
  type?: string;
  image?: string;
  verifyType?: string;
  org: '';
  subdomain?: string;
  orgEmail?: string;
  lang: string;
  allowFullAccess?: boolean;
  allowedClients: string[];
}

export interface OrganizationManager extends BaseUser {
  createdAt?: string;
  tokenId?: string;
  stripeId?: string;
  role?: string;
  orgId?: string;
  passwordUpdated?: boolean;
  pendingRegistration?: boolean;
  patron?: Patron;
  type?: string;
  image?: string;
  allowFullAccess?: boolean;
  allowedClients: string[];
  verifyType?: string;
  org: '';
  subdomain?: string;
  orgEmail?: string;
  lang: string;
}

export interface PatronData {
  patrons: Patron;
  accountId: string;
}

export enum UsersType {
  CLIENT = 'clients',
  ORGANIZATION = 'organizations',
  MANAGERS_AND_ADMINS = 'managersAndAdmins',
  ADMIN = 'admin',
  MANAGER = 'manager',
  STUDENT = 'students',
}

export enum ManagerActions {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum ManagerTypes {
  client = 'Client',
  outsider = 'Outsider',
}

export enum Templates {
  LOADING = 'loading',
  TABLE = 'table',
  LOADED = 'loaded',
}
