<app-main-layout [breadcrumbs]="[{ name: 'Home', url: '/' }]">
  <div
    class="mainContainer col-8 px-0 m-auto my-3"
    *ngIf="!loading"
  >
    <div class="container">
      <div class="title">
        <h2>Complete this form to continue</h2>
      </div>

      <div
        class="organization-type-container"
        *ngIf="showOrganizationTypeContainer && isSchool !== null"
        [@fadeInOut]
      >
        <h3>Which option best describes your organization?</h3>

        <div class="radio-options">
          <div class="radio-option">
            <input
              type="radio"
              id="organization"
              name="isSchool"
              [(ngModel)]="isSchool"
              [value]="false"
              (change)="onOrganizationTypeSelected(false)"
            />
            <div class="radio-content">
              <label for="organization">I am an organization</label>
              <p class="description">
                If you represent a company, clinic, or another professional
                organization.
              </p>
            </div>
          </div>

          <div class="radio-option">
            <input
              type="radio"
              id="school"
              name="isSchool"
              [(ngModel)]="isSchool"
              [value]="true"
              (change)="onOrganizationTypeSelected(true)"
            />
            <div class="radio-content">
              <label for="school">I represent an education institution</label>
              <p class="description">
                If you represent a school or a school board.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content"
        *ngIf="showContentContainer"
        [@fadeInOut]
      >
        <div class="profile-photo-container">
          <div
            *ngIf="organizationForm.logo"
            class="profile-photo-preview"
          >
            <img
              [src]="
                organizationForm.logo ||
                './assets/img/StudentImagePlaceholder.png'
              "
              alt="Student photo preview"
              class="preview-image"
              (click)="addOrganizationImage()"
            />
            <div class="hover-content">
              <button (click)="addOrganizationImage()">
                <fa-icon [icon]="camera"></fa-icon>
                <span>Change your picture</span>
              </button>
            </div>
          </div>
          <div
            *ngIf="!organizationForm.logo"
            class="profile-photo-button"
          >
            <button (click)="addOrganizationImage()">
              <fa-icon [icon]="camera"></fa-icon>
            </button>
          </div>
        </div>

        <form
          #form="ngForm"
          novalidate
        >
          <div class="container-split">
            <div class="container-left-column">
              <div class="input-block">
                <label for="organizationForm.name"
                  >{{ isSchool ? 'Institution Name' : 'Organization Name'
                  }}<span>*</span></label
                >
                <input
                  type="text"
                  name="organizationForm.name"
                  [(ngModel)]="organizationForm.name"
                  (blur)="fillSubdomain()"
                  required
                  #name="ngModel"
                  [ngClass]="{
                    'is-invalid': name.invalid && (name.dirty || name.touched)
                  }"
                />
                <div
                  *ngIf="name.invalid && (name.dirty || name.touched)"
                  class="invalid-feedback"
                >
                  <div *ngIf="name.errors?.required">The Name is required.</div>
                </div>
              </div>

              <div class="input-block">
                <label for="organizationForm.phoneNumber"
                  >Phone Number <span>*</span></label
                >
                <input
                  type="text"
                  name="organizationForm.phoneNumber"
                  [(ngModel)]="organizationForm.phoneNumber"
                  required
                  #phone="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      phone.invalid && (phone.dirty || phone.touched)
                  }"
                />
                <div
                  *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                  class="invalid-feedback"
                >
                  <div *ngIf="phone.errors?.required">
                    The Phone is required.
                  </div>
                </div>
              </div>

              <div class="input-group">
                <div class="input-block">
                  <label for="organizationForm.position"
                    >{{
                      'Profession'
                        | textTransform
                          : (isSchool
                              ? PortalConfig.SCHOOL
                              : PortalConfig.DEFAULT)
                    }}<span>*</span></label
                  >
                  <input
                    type="text"
                    name="organizationForm.position"
                    [(ngModel)]="organizationForm.position"
                    required
                    #position="ngModel"
                  />
                  <div
                    *ngIf="
                      position.invalid && (position.dirty || position.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="position.errors?.required">
                      {{
                        'Profession'
                          | textTransform
                            : (isSchool
                                ? PortalConfig.SCHOOL
                                : PortalConfig.DEFAULT)
                      }}
                      is required
                    </div>
                  </div>
                </div>

                <div class="input-block">
                  <label for="organizationForm.professionalAssociation"
                    >{{
                      'Professional Association'
                        | textTransform
                          : (isSchool
                              ? PortalConfig.SCHOOL
                              : PortalConfig.DEFAULT)
                    }}
                    <span *ngIf="isSchool">*</span>
                  </label>
                  <input
                    type="text"
                    name="organizationForm.professionalAssociation"
                    [(ngModel)]="organizationForm.professionalAssociation"
                    #professionalAssociation="ngModel"
                    [required]="isSchool"
                  />
                  <div
                    *ngIf="
                      isSchool &&
                      professionalAssociation.invalid &&
                      (professionalAssociation.dirty ||
                        professionalAssociation.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        isSchool && professionalAssociation.errors?.required
                      "
                    >
                      {{
                        'Professional Association'
                          | textTransform
                            : (isSchool
                                ? PortalConfig.SCHOOL
                                : PortalConfig.DEFAULT)
                      }}
                      is required
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-right-column">
              <div class="input-group">
                <div class="input-block">
                  <label for="organizationForm.address.address"
                    >Address (number & state)<span>*</span></label
                  >
                  <input
                    type="input"
                    name="organizationForm.address.address"
                    [(ngModel)]="organizationForm.address.address"
                    ngx-gp-autocomplete
                    (onAddressChange)="handleAddressChange($event)"
                    placeholder="Enter your address"
                    required
                    #address="ngModel"
                  />
                  <div
                    *ngIf="
                      address.invalid && (address.dirty || address.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="address.errors?.required">
                      The Address is required.
                    </div>
                  </div>
                </div>

                <div class="input-block">
                  <label for="organizationForm.address.postalCode"
                    >Postal/Zip Code<span>*</span></label
                  >
                  <input
                    type="text"
                    name="organizationForm.address.postalCode"
                    [(ngModel)]="organizationForm.address.postalCode"
                    required
                    #postalCode="ngModel"
                  />
                  <div
                    *ngIf="
                      postalCode.invalid &&
                      (postalCode.dirty || postalCode.touched)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="postalCode.errors?.required">
                      The Postal Code is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="input-group">
                <div class="input-block">
                  <label for="organizationForm.address.country"
                    >Country<span>*</span></label
                  >
                  <input
                    [disabled]="true"
                    type="text"
                    name="organizationForm.address.country"
                    [(ngModel)]="organizationForm.address.country"
                  />
                </div>

                <div class="input-block">
                  <label for="organizationForm.address.province"
                    >Province/State<span>*</span></label
                  >
                  <input
                    [disabled]="true"
                    type="text"
                    name="organizationForm.address.province"
                    [(ngModel)]="organizationForm.address.province"
                  />
                </div>
              </div>

              <div class="input-block">
                <label for="organizationForm.referredBy">Referred by</label>
                <input
                  type="text"
                  name="organizationForm.referredBy"
                  [(ngModel)]="organizationForm.referredBy"
                />
              </div>
            </div>
          </div>
          <div class="subdomain-container">
            <div class="subdomain-label-container">
              <label for="organizationForm.subdomain">Subdomain</label>
            </div>
            <div class="subdomain-input-container">
              <div class="subdomain-prefix">https://</div>
              <input
                type="text"
                id="subdomain"
                name="organizationForm.subdomain"
                [(ngModel)]="organizationForm.subdomain"
                required
              />
              <div class="subdomain-suffix">.ls.works</div>
            </div>
          </div>

          <div class="terms">
            <div>
              <div class="termsOfService">
                <div (scroll)="onScroll($event)">
                  <app-terms-of-service-box></app-terms-of-service-box>
                </div>

                <p>
                  * To activate the I accept the Terms of Service button. You
                  must scroll to the end
                </p>
              </div>

              <br />
              <mat-checkbox
                name="organizationForm.acceptedTerms"
                [disabled]="!allowTermsAgree"
                [(ngModel)]="organizationForm.acceptedTerms"
              >
                I accept the Terms of Service
              </mat-checkbox>

              <div class="col-12 my-3 px-0 d-flex justify-content-center">
                <div>
                  <app-simple-button
                    [disabled]="!form.valid || !organizationForm.acceptedTerms"
                    class="m-auto submitBtn"
                    text="SAVE"
                    (click)="updateOrganization()"
                  ></app-simple-button>
                </div>

                <div *ngIf="updatingOrg">
                  <div class="d-flex justify-content-center align-items-center">
                    <fa-icon
                      class="mx-2"
                      [icon]="spinnerIcon"
                      [spin]="true"
                    >
                    </fa-icon>

                    <div>Please wait, we're saving your information.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-main-layout>
