<ng-container *ngIf="template === 'loaded'">
  <users-table-header heading="Students"></users-table-header>

  <div class="search-and-add">
    <users-table-search-input
      placeholder="Search students..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>
    <app-simple-button
      class="btn-add-student"
      text="Add Student"
      (click)="handleAddStudent()"
      [smaller]="true"
    ></app-simple-button>
  </div>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let student">
          {{ student.givenName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nickname">
        <mat-header-cell *matHeaderCellDef> Nickname </mat-header-cell>
        <mat-cell *matCellDef="let student">
          {{ student.nickname }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Age </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <span>{{ student.age }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="program">
        <mat-header-cell *matHeaderCellDef> Program </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <ng-container
            *ngIf="
              student.tokens?.length > 0;
              then neuralignBadge;
              else noProgramBadge
            "
          ></ng-container>
          <ng-template #neuralignBadge>
            <users-neuralign-badge></users-neuralign-badge>
          </ng-template>
          <ng-template #noProgramBadge>
            <users-no-program-badge></users-no-program-badge>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <div class="icons">
            <users-table-add-program-button
              [student]="student"
              (onAddProgramSuccess)="handleAddProgramSuccess()"
            ></users-table-add-program-button>
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit user'"
              (click)="editClientStudent(student)"
            ></users-table-action-icon>
            <users-table-action-icon
              *ngIf="canAccessNeuralign(student)"
              [icon]="'game'"
              [tooltip]="'Access Neuralign'"
              (click)="accessNeuralign(student)"
            ></users-table-action-icon>
            <users-table-action-icon
              *ngIf="!canAccessNeuralign(student)"
              [icon]="'game'"
              [tooltip]="
                'Please assign a credit to this student to access Neuralign'
              "
              [disabled]="true"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete user'"
              (click)="deleteClientStudent(student)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="
          ['name', 'nickname', 'age', 'program', 'actions'];
          sticky: true
        "
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let row;
          columns: ['name', 'nickname', 'age', 'program', 'actions']
        "
      ></mat-row>
    </mat-table>
  </div>
</ng-container>

<ng-container *ngIf="template === 'unselected'">
  <users-empty-state
    template="unselected"
    text="Please select a client from the left to view their students"
  ></users-empty-state>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>

<ng-container *ngIf="template === 'noStudents'">
  <users-empty-state
    template="noStudents"
    text="The selected Client does not have any students"
    (onAddStudent)="handleAddStudent()"
  ></users-empty-state>
</ng-container>
